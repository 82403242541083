<template>
  <validation-observer
    ref="creditClassSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="creditClassSaveModal"
      body-class="position-static"
      centered
      size="xl"
      no-close-on-backdrop
      :title="isCreated ? `Thêm ${resourceName.fullName}` : `Cập nhật ${resourceName.fullName}`"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Hệ đào tạo <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Hệ đào tạo"
                rules="required"
              >
                <v-select
                  v-model="targetData.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  id="selectCourses"
                  v-model="targetData.courseId"
                  :options="courses"
                  label="name"
                  :reduce="option => option.id"
                  @input="onCoursesChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="courseSemesterId">
              <template v-slot:label>
                Học kỳ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kỳ"
                rules="required"
              >
                <v-select
                  v-model="targetData.courseSemesterId"
                  :options="courseSemesters"
                  label="name"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="classId">
              <template v-slot:label>
                Lớp cố định
                <span
                  v-if="isCopy"
                  class="text-danger"
                >
                  *
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Lớp cố định"
                :rules="(!isCreated || !isCopy) ? '' : 'required'"
              >
                <v-select
                  v-model="targetData.classId"
                  :options="classes"
                  :reduce="option => option.id"
                  label="name"
                  :disabled="!isCreated || !isCopy"
                  @input="onClassChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="departmentId">
              <template v-slot:label>
                Khoa/Bộ môn
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khoa/Bộ môn"
              >
                <v-select
                  v-model="targetData.departmentId"
                  :options="departments"
                  :reduce="option => option.id"
                  label="name"
                  @input="onDepartmentChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isCopy"
            sm="12"
            md="12"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="majorId"
            >
              <template v-slot:label>
                Chuyên ngành
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chuyên ngành"
              >
                <v-select
                  v-model="targetData.majorId"
                  :options="majors"
                  :reduce="option => option.id"
                  label="name"
                  @input="onMajorChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isCopy"
            sm="12"
            md="12"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="programmeId"
            >
              <template v-slot:label>
                Chương trình đào tạo
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo"
              >
                <v-select
                  v-model="targetData.programmeId"
                  :options="programmes"
                  :reduce="option => option.id"
                  label="name"
                  @input="onProgrammeChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
            md="12"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="subjectId">
              <template v-slot:label>
                Môn học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Môn học"
                rules="required"
              >
                <v-select
                  v-model="targetData.subjectId"
                  :options="subjects"
                  :reduce="option => option.id"
                  label="name"
                  @input="onSubjectChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã lớp học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã lớp học"
                rules="required"
              >
                <b-form-input
                  id="maxQty"
                  v-model="targetData.code"
                  name="code"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isCreated"
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="copyNum">
              <template v-slot:label>
                Số lượng lớp <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số lớp"
                rules="required"
              >
                <b-form-input
                  id="maxQty"
                  v-model="targetData.copyNum"
                  name="maxQty"
                  :state="getElementState(errors)"
                  type="number"
                  @input="onCopyNumChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="maxQty">
              <template v-slot:label>
                Sĩ số <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Sĩ số"
                :rules="required"
              >
                <b-form-input
                  id="maxQty"
                  v-model="targetData.maxQty"
                  type="number"
                  name="maxQty"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            class="d-flex align-items-center"
            sm="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group label-for="type">
              <b-form-checkbox
                id="type"
                v-model="targetData.type"
                class="mr-0"
                name="type"
                :value="2"
                :unchecked-value="1"
                :disabled="!isCreated"
              >
                Là lớp thực hành
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="3"
            xl="3"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="12"
            lg="2"
            xl="2"
          >
            <b-form-group
              label="Thứ tự"
              label-for="orderNo"
            >
              <b-form-input
                id="orderNo"
                v-model="targetData.orderNo"
                readonly
                :disabled="targetData.autoCreated"
                type="number"
                name="orderNo"
                placeholder="Nhập thứ tự"
              />
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group
              label="Mở lớp LMS"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="targetData.syncLms"
                name="syncLms"
                value="1"
                unchecked-value="0"
                :disabled="!isCreated || !isCopy"
              >
                Đồng bộ sang LMS
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-if="isCopy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-if="!isCopy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-if="isCopy"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lớp
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('creditClassSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'

export default {
  name: 'CreditClassSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BCol,
    BRow,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: null,
    },
    isCopy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetData: {
        id: 0,
        code: '',
        organizationId: null,
        trainingSystemId: 0,
        courseId: 0,
        courseSemesterId: 0,
        semesterId: 0,
        departmentId: 0,
        majorId: 0,
        programmeId: 0,
        classId: null,
        subjectId: 0,
        maxQty: null,
        status: 1,
        orderNo: 1,
        isScoreLocked: Flag.UNLOCK_SCORE,
        examinationScoreLocked: Flag.UNLOCK_SCORE,
        copyNum: 1,
        type: 1,
        syncLms: 0,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'creditClass/statuses',
      resourceName: 'creditClass/resourceName',
      maxOrderNo: 'creditClass/maxOrderNo',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'course/courses',
      departments: 'department/departments',
      majors: 'major/majors',
      classes: 'classes/classes',
      courseSemesters: 'courseSemester/dataLists',
      programmes: 'programme/programmes',
      subjects: 'subject/subjects',
      nextCode: 'creditClass/nextCode',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'creditClass/createData',
      copyData: 'creditClass/copyData',
      updateData: 'creditClass/updateData',
      getMaxOrderNo: 'creditClass/getMaxOrderNo',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'course/getCourses',
      getCourseSemesters: 'courseSemester/getData',
      getDepartments: 'department/readDepartments',
      getMajors: 'major/readMajors',
      getClasses: 'classes/getClasses',
      getProgrammes: 'programme/getProgrammes',
      getSubjects: 'subject/readSubjects',
      getNextCode: 'creditClass/getNextCode',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onTrainingSystemsChange(event) {
      this.isLoading = true
      this.targetData.courseId = null
      await this.getCourses({ trainingSystemId: event, currentPage: 1, itemsPerPage: 10000 })
      await this.getClasses({ trainingSystemId: event, currentPage: 1, itemsPerPage: 10000 })
      this.isLoading = false
    },
    async onCoursesChange(event) {
      this.isLoading = true
      this.targetData.courseSemesterId = null
      await Promise.all([
        this.getCourseSemesters({ organizationId: getUser().orgId, courseId: event, currentPage: 1, itemsPerPage: 10000, status: 1 }),
        this.getProgrammes({
          departmentId: this.targetData.departmentId,
          majorId: this.targetData.majorId,
          courseId: this.targetData.courseId,
          organizationId: getUser().orgId,
          currentPage: 1,
          itemsPerPage: 10000,
        }),
        this.getClasses({ organizationId: getUser().orgId, trainingSystemId: this.targetData.trainingSystemId, courseId: event, currentPage: 1, itemsPerPage: 10000 }),
      ])
      this.isLoading = false
    },
    async onDepartmentChange(event) {
      this.isLoading = true
      this.targetData.subjectId = null
      this.targetData.majorId = null
      this.targetData.programmeId = null

      if (this.isCopy) {
        await this.getMajors({ organizationId: getUser().orgId, departmentId: event, currentPage: 1, itemsPerPage: 10000 })
        if (this.majors.length > 0) {
          this.targetData.majorId = this.majors[0].value
        }

        await this.getProgrammes({
          organizationId: getUser().orgId,
          departmentId: this.targetData.departmentId,
          majorId: this.targetData.majorId,
          courseId: this.targetData.courseId,
          currentPage: 1,
          itemsPerPage: 10000,
        })
        if (this.programmes.length > 0) {
          this.targetData.programmeId = this.programmes[0].value
        }
      }

      await this.getSubjects({ organizationId: getUser().orgId, departmentId: event, programmeId: this.targetData.programmeId, currentPage: 1, itemsPerPage: 10000 })
      this.isLoading = false
    },
    async onMajorChange(event) {
      this.isLoading = true
      this.targetData.classId = null
      this.targetData.programmeId = null
      this.targetData.subjectId = null

      await Promise.all([
        this.getProgrammes({
          organizationId: getUser().orgId, departmentId: this.targetData.departmentId, majorId: event, courseId: this.targetData.courseId, currentPage: 1, itemsPerPage: 10000
        }),
      ])

      if (event !== null) {
        if (this.programmes.length > 0) {
          this.targetData.programmeId = this.programmes[0].id
        }
      } else {
        this.targetData.programmeId = null
      }
      await this.getSubjects({ organizationId: getUser().orgId, departmentId: this.targetData.departmentId, programmeId: this.targetData.programmeId, currentPage: 1, itemsPerPage: 10000 })
      this.isLoading = false
    },
    async onProgrammeChange(event) {
      this.isLoading = true
      this.targetData.subjectId = null
      // await this.getClasses({ majorId: this.targetData.classId })
      await this.getSubjects({ organizationId: getUser().orgId, departmentId: this.targetData.departmentId, programmeId: event, currentPage: 1, itemsPerPage: 10000 })
      this.isLoading = false
    },
    onClassChange(event) {
      // lay sy so lop hoc
      const copyNum = this.classes.find(e => e.id === event).capacity || 1
      this.targetData.maxQty = Math.ceil(copyNum / this.targetData.copyNum)
    },
    async onSubjectChange(event) {
      this.isLoading = true
      // lay ma lop hoc
      const request = {
        programmeId: this.targetData.programmeId,
        courseSemesterId: this.targetData.courseSemesterId,
        subjectId: event,
      }
      await Promise.all([
        this.getNextCode(request),
        this.getMaxOrderNo({
          courseSemesterId: this.targetData.courseSemesterId,
          subjectId: this.targetData.subjectId,
        }),
      ])
      this.targetData.code = this.nextCode
      this.targetData.orderNo = this.maxOrderNo + 1
      this.isLoading = false
    },
    onCopyNumChange(event) {
      const copyNum = event || 1
      try {
        this.targetData.maxQty = Math.ceil(this.classes.find(e => e.id === this.targetData.classId).capacity / copyNum)
      } catch (e) {
        // this.targetData.maxQty = 1
      }
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        const curData = {
          id: this.mainData.id,
          code: this.mainData.code,
          trainingSystemId: this.mainData.trainingSystemId,
          courseId: this.mainData.courseId,
          courseSemesterId: this.mainData.courseSemesterId,
          departmentId: this.mainData.departmentId,
          majorId: this.mainData.majorId,
          classId: this.mainData.classId,
          subjectId: this.mainData.subjectId,
          status: this.mainData.status,
          maxQty: this.mainData.maxQty,
          orderNo: this.mainData.orderNo || 1,
          isScoreLocked: this.mainData.isScoreLocked,
          examinationScoreLocked: this.mainData.examinationScoreLocked,
          type: this.mainData.type,
          syncLms: this.mainData.syncLms,
        }
        this.targetData = { ...curData }
      }

      if (this.isCreated) {
        await Promise.all([
          this.getTrainingSystems({ organizationId: getUser().orgId }),
          this.getDepartments({ organizationId: getUser().orgId, currentPage: 1, itemsPerPage: 10000 }),
        ])

        if (this.trainingSystems.length > 0) {
          this.targetData.trainingSystemId = this.trainingSystems[0].value || ''
          await this.getCourses({ organizationId: getUser().orgId, trainingSystemId: this.trainingSystems[0].value || '', currentPage: 1, itemsPerPage: 10000 })
        }
        if (this.departments.length > 0) {
          this.targetData.departmentId = this.departments[0].value
          await this.getMajors({ organizationId: getUser().orgId, departmentId: this.targetData.departmentId, currentPage: 1, itemsPerPage: 10000 })
        }
        await this.getSubjects({ organizationId: getUser().orgId, departmentId: this.targetData.departmentId, currentPage: 1, itemsPerPage: 10000 })
        this.targetData.orderNo = this.maxOrderNo + 1
        this.targetData.status = 0
      } else {
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.user.orgId }),
          this.getCourses({ organizationId: getUser().orgId, trainingSystemId: this.targetData.trainingSystemId || '', currentPage: 1, itemsPerPage: 10000 }),
          this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.targetData.courseId, currentPage: 1, itemsPerPage: 10000 }),
          this.getDepartments({ organizationId: this.user.orgId, currentPage: 1, itemsPerPage: 10000 }),
          this.getSubjects({ organizationId: getUser().orgId, departmentId: this.targetData.departmentId, currentPage: 1, itemsPerPage: 10000 }),
        ])
      }

      if (this.isCopy) {
        this.targetData.status = 3
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .creditClassSaveFormRef
        .reset()
      this.targetData = {
        id: null,
        courseId: null,
        courseSemesterId: null,
        semesterId: null,
        departmentId: null,
        majorId: null,
        classId: null,
        subjectId: null,
        status: 1,
        orderNo: null,
        isScoreLocked: Flag.UNLOCK_SCORE,
        examinationScoreLocked: Flag.UNLOCK_SCORE,
        copyNum: 1,
        type: 1,
        syncLms: 0,
      }
      // this.isCopy = false
    },
    async onSave(type = null) {
      if (this.isCreated && this.targetData.classId) {
        const { capacity } = this.classes.find(e => e.id === this.targetData.classId)
        if (!capacity) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Lớp cố định chưa có sinh viên, vui lòng thêm sinh viên vào lớp',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          })
          return
        }
        if (this.targetData.copyNum > capacity) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Số lượng lớp không được vượt quá ${capacity}`,
              icon: 'InfoIcon',
              variant: 'warning',
            },
          })
          return
        }
      }
      const valid = this.$refs
        .creditClassSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          let response = null
          if (this.isCreated) {
            this.targetData.isEmptyClass = this.isCopy ? 0 : 1
            response = this.isCopy ? await this.copyData(this.targetData) : await this.createData(this.targetData)
          } else {
            response = await this.updateData(this.targetData)
          }
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('creditClassSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
